import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title text="Projects" mdxType="Title" />
    <section sx={{
      mb: [5, 6, 7]
    }}>
  <a href="https://github.com/launch-io/launch.io">Launch.IO</a>
  <p>
    An Ultra Hip, Simple, Smart, and Fast, Time Traveling React State Management
    Library.
  </p>
    </section>
    <section sx={{
      mb: [5, 6, 7]
    }}>
  <a href="https://github.com/jbillmann/GarageServer.IO">GarageServer.IO</a>
  <p>
    A Simple, Lightweight, HTML Multiplayer Game Server (and Client) for Node.js
  </p>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      